// node の祖先のうち、node に一番近い query にマッチする要素を返す
export function closest(query, node) {
	if (!node) return null
	if (typeof query !== 'string') return closestNode(query, node)

	// テキストノードやコメントノードに対しても取得できるようにする
	if (node.nodeType === 3 || node.nodeType === 8) {
		node = node.parentNode
	}

	// ネイティブの closest メソッドを使う
	if (window.Element && Element.prototype.closest) {
		if (!node.closest) return null
		return node.closest(query)
	}

	// Polyfill for IE11
	const matches = (node.document || node.ownerDocument).querySelectorAll(query)
	let el = node
	let i
	do {
		i = matches.length
		while (--i >= 0 && matches[i] !== el) {}
	} while (i < 0 && (el = el.parentNode))
	return el
}

// node の祖先に targetNode があれば targetNode を返す
export function closestNode(targetNode, node) {
	while (node) {
		if (node === targetNode) {
			return node
		}
		node = node.parentNode
	}
	return null
}
