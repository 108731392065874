import { closest } from './dom-closest'

function entryFormSupport () {
	[].forEach.call(document.querySelectorAll('.entry-form-controls input[type="checkbox"][value="other"]'), input => {
		const checkboxDiv = closest('.checkbox', input)
		if (!checkboxDiv) return
		checkboxDiv.classList.add('checkbox-other')
	})
	;[].forEach.call(document.querySelectorAll('.entry-form-controls input[type="radio"][value="other"]'), input => {
		const checkboxDiv = closest('.radio', input)
		if (!checkboxDiv) return
		checkboxDiv.classList.add('checkbox-other')
	})
}

export default entryFormSupport
