import initMobileNav from './mobile-nav'
import { initAjaxzip3OnInput } from './ajaxzip3'
import entryFormSupport from './entryFormSupport'

// import './debug-font'

// 読み込み位置を body の最後にしてあるため DOMReady 不要
initMobileNav()
initAjaxzip3OnInput()
entryFormSupport()
