import { closest, closestNode } from './dom-closest'

const classPrefix = 'mobile-nav'

let opened = false
let rootE = null
let navE = null

function initMobileNav() {
	rootE = document.documentElement
	navE = document.querySelector(`.${classPrefix}`)

	// Reset DOM
	rootE.classList.remove(`${classPrefix}-on`, `${classPrefix}-on-long`)

	// Listen events
	observeTouch()
}

function observeTouch() {
	let startEvent
	let clickHandler = toggleMobileNav

	const startHandler = (event) => {
		startEvent = event

		if (!event.touches || event.touch.length !== 1) return

		// タッチが有効ならクリックイベントを無効にする
		if (clickHandler) {
			rootE.removeEventListener('click', clickHandler)
			clickHandler = null
		}

		rootE.addEventListener('touchmove', moveHandler)
		rootE.addEventListener('touchend', endHandler)
	}

	const cancelTouchHandler = () => {
		rootE.removeEventListener('touchmove', moveHandler)
		rootE.removeEventListener('touchend', endHandler)
	}

	const moveHandler = (event) => {
		// 1本指タッチ以外は無視する
		if (!event.touches || event.touches.length !== 1) {
			cancelTouchHandler()
			return
		}

		const distance = sqdist(
			startEvent.touches[0].pageX, startEvent.touches[0].pageY,
			event.touches[0].pageX, event.touches[0].pageY
		)
		if (distance > 100) {
			// 移動が一定距離を超えたらタップ判定をキャンセルする
			cancelTouchHandler()
		}
	}

	const endHandler = (event) => {
		const insideLink = closet('a', event.target)
		if (insideLink) return

		event.preventDefault()
		event.stopPropagation()
		toggleMobileNav(startEvent)
	}

	rootE.addEventListener('touchstart', startHandler)
	rootE.addEventListener('click', clickHandler)
}

function toggleMobileNav(event) {
	if (opened) {
		const insideNavE = closest(navE, event.target) != null
		const insideLink = closest('a', event.target) != null
		// console.log(`toggleMobileNav (opened, insideNavE = ${insideNavE}, insideLink = ${insideLink})`)
		if (!insideNavE || insideLink) {
			closeMobileNav()
		}
	} else {
		const insideNavToggler = closest(`.${classPrefix}-toggle`, event.target) != null
		// console.log(`toggleMobileNav (not opened, insideNavToggler = ${insideNavToggler})`)
		if (insideNavToggler) {
			openMobileNav()
		}
	}
}

function openMobileNav() {
	if (rootE.offsetHeight > window.innerHeight) {
		rootE.classList.add(`${classPrefix}-on-long`) // スクロールバーを考慮するトリック
	}
	rootE.classList.add(`${classPrefix}-on`)
	opened = true
}

function closeMobileNav() {
	rootE.classList.remove(`${classPrefix}-on`, `${classPrefix}-on-long`)
	opened = false
}

// 距離の2乗を取得する
function sqdist(x1, y1, x2, y2) {
	return ((x1 - x2) ** 2) + ((y1 - y2) ** 2)
}

export default initMobileNav
